<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading } from "@/components";

export default {
  name: "PromotingYourEvent",
  components: { ResourceFrame, SuawInputGroup, SuawHeading }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Promoting Your <b>Event</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Getting the word out about your Shut Up & Write! group is a key part of being an organizer. Here are some tips on boosting your event's visibility."
      />

      <div class="suaw-organizer-resource-guide">
        <ul class="suaw-organizer-resource-guide__list">
          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Social Media:</b> Posting about your group on Facebook, Instagram, or any other social media platform can be very helpful. <b>We ask that you use your own social media account(s) rather than creating an account with the "Shut Up & Write!" name for your group. </b>
              <router-link :to="{ name: 'EventsToolkit', hash: '#social-media' }" class="text-primary hover:text-primary-dark">Download social media templates here.</router-link>
            </p>
          </li>
          <p class="suaw-paragraph">
            If you post on social media about your Shut Up & Write! group, 
            <a href="https://www.instagram.com/shutupwrite" target="_blank" rel="noopener noreferrer">tag us!</a> 
            We'd love to help you get the word out, so if you tag us, we can re-share your post.
          </p>

          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Flyers:</b> Print out flyers & leave them around town at coffee shops, libraries, bookstores, or anywhere with community bulletin boards. Think about visibility – where might a possible member be hanging out in order to see your flyer? 
              <router-link :to="{ name: 'EventsToolkit', hash: '#flyers' }">Download PDF flyers here</router-link>.
            </p>
          </li>

          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Table Tents:</b> If you host an in-person event, print out a table tent and set it up at your venue so participants know where to go. 
              <router-link :to="{ name: 'EventsToolkit', hash: '#table-tent' }">Download a table tent PDF here</router-link>.
            </p>
          </li>

          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Local Partnerships:</b> Reach out to local businesses, such as bookstores, cafes, or community centers, to see if they'd be willing to help promote your group. They might let you leave flyers or mention your group in their newsletters. Building these partnerships can enhance your group's credibility and visibility.
            </p>
          </li>

          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Word of Mouth:</b> Encourage current members to invite friends or share their experiences about the group. Personal recommendations can be incredibly effective, so consider creating a referral incentive, like a small reward for members who bring new participants.
            </p>
          </li>

          <li class="suaw-organizer-resource-guide__item">
            <p class="suaw-paragraph">
              <b>Be Creative:</b> Here's where you can flex your creative muscles. Try to identify places that writers in your area might be frequenting, either in person or online (like a FB group for local writers) and spread the word in those places. You know your local area best!
            </p>
          </li>
        </ul>
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
